import gql from 'graphql-tag';

export default gql`
query getPersonalizedVariableValues($setId: Int!) {
  getPersonalizedVariableValues(
    object: {
      personalized_variable_set_id: $setId
    }
  ) {
    value_order
    name
    client_key
    variable_data
    enabled
  }
}
`;
