import gql from 'graphql-tag';

export default gql`
mutation insertPersonalizedVariableValue(
  $obj:  personalizedVariableValueInput!
) {
  insertPersonalizedVariableValue(object: $obj) {
    personalized_variable_set_id
    client_key
  }
}
`;
