import gql from 'graphql-tag';

export default gql`
mutation deletePersonalizedVariableValues($id: String!, $setId: Int!) {
  deletePersonalizedVariableValue(
    object: {
      client_key: $id,
      personalized_variable_set_id: $setId
    }
  ) {
    client_key
    personalized_variable_set_id
  }
}
`;
