<template>
  <b-row class="mb-5">
    <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
      <h3 class="mb-0 text-uppercase">
        {{ $t('settings.personalization.contacts') }}
      </h3>
      <b-dropdown
          v-if="hasWriteAccess"
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
      >
        <template #button-content>
          <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
        </template>
        <b-dropdown-item
          v-if="isImportedContacts"
          @click="showReimportContactsModal = true"
        >
          {{ $t('dropdowns.reimport') }}
        </b-dropdown-item>
        <b-dropdown-item disabled>
          {{ $t('dropdowns.import') }}
        </b-dropdown-item>
        <b-dropdown-item disabled>
          {{ $t('dropdowns.export') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col cols="12" sm="8" class="align-content-end">
      <div class="float-right d-flex">
        <b-input-group
          :class="`search-group d-flex align-items-center ${hasWriteAccess ? 'mr-3' : 'mr-0'}`">
          <b-icon
              icon="search"
              aria-hidden="true"
              scale="1"
          ></b-icon>
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$t('webmag.search')"
          ></b-form-input>
        </b-input-group>
        <b-button
            v-if="hasWriteAccess"
            class="green-button-transparent border-0"
            @click="showNewPersonalizationEntryModal = true"
        >
          <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
          ></b-icon>
          {{ $t('buttons.addPersonalizationEntry') }}
        </b-button>
      </div>
      <modal-create-new-personalization-entry
          v-if="showNewPersonalizationEntryModal"
          :set-id="setId"
          @create-personalization-entry="createNewPersonalizationEntry"
          @close-create-entry="showNewPersonalizationEntryModal = false">
      </modal-create-new-personalization-entry>
      <modal-rename-contact
          v-if="showUpdatePersonalizationValueModal"
          :id-prop="currentContactId"
          :name-prop="currentContactName"
          @rename-value="renameContact"
          @close-create-value-modal="closeRenameValueModal"
      />
      <modal-delete-contact
          v-if="showDeleteContactModal"
          :id-prop="currentContactId"
          @delete-contact="deleteContact"
          @close-delete-entry-modal="closeDeleteContactModal"
      />
      <modal-reimport-contacts
        v-if="showReimportContactsModal"
        :personalization-set="personalizationSet"
        @reimport-microsoft-data="reimportContacts"
        @close-modal="closeReimportContactsModal"
      />
    </b-col>
    <b-col
      v-if="selectedContacts.length > 0"
      cols="12"
      offset-sm="1"
      sm="11"
      class="mt-4"
    >
      <contacts-row-slide
          v-for="(contact, index) in selectedContacts"
          :key="index"
          :order="index + 1"
          :id="contact.client_key"
          :name="contact.name"
          :schema="contactsSchema"
          :value-is-saved="valueIsSaved"
          :values="contact.variable_data"
          :has-write-access="hasWriteAccess"
          @open-rename-modal="openRenameModal"
          @update-field-value="updateFieldValue"
          @delete-contact="openDeleteContactModal"
          @value-save-status-completed="valueIsSaved = false"
          @row-rendered="contactRowRendered"
      />
    </b-col>
    <b-col
      v-else
      cols="12"
      offset-sm="1"
      sm="11"
      class="mt-4"
    >
      <div class="empty-contact py-2 text-center">
        {{ $t('settings.personalization.emptyContacts') }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
import InsertPersonalizedVariableValue from '@/graphQlQueries/mutations/insertPersonalizedVariableValue';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import GetPersonalizedVariableValues from '@/graphQlQueries/queries/getPersonalizedVariableValues';
import DeletePersonalizedVariableValues from '@/graphQlQueries/mutations/deletePersonalizedVariableValues';
import UpdatePersonalizedVariableValues from '@/graphQlQueries/mutations/updatePersonalizedVariableValues';

export default {
  name: 'PersonalizationContacts',
  components: {
    ContactsRowSlide: () => import('@/components/settings/personalization/ContactsRowSlide.vue'),
    ModalRenameContact: () => import('@/components/modals/personalization/ModalRenameContact.vue'),
    ModalDeleteContact: () => import('@/components/modals/personalization/ModalDeletePersonalizationEntry.vue'),
    ModalReimportContacts: () => import('@/components/modals/personalization/ModalReimportContacts.vue'),
    ModalCreateNewPersonalizationEntry: () => import('@/components/modals/personalization/ModalCreateNewPersonalizationEntry.vue'),
  },
  props: {
    schema: {
      type: Array,
      default: null,
    },
    setId: {
      type: Number,
      required: true,
    },
    setType: {
      type: Number,
      default: null,
    },
    personalizationSet: {
      type: Object,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: '',
      showEditContactModal: false,
      showDeleteContactModal: false,
      showNewPersonalizationEntryModal: false,
      showUpdatePersonalizationValueModal: false,
      showReimportContactsModal: false,
      currentPersonalizationSet: null,
      contacts: [],
      deletedSchemaFieldIds: [],
      currentContactId: null,
      currentContactName: null,
      personalizationVariables: null,
      valueIsSaved: false,
      numberOfContactRows: 0,
    };
  },
  filters: {
    sliceStingPos(value, length) {
      return (value.length > length) ? `${value.slice(0, length)}...` : value;
    },
  },
  computed: {
    isImportedContacts() {
      // webmag type is 1
      return this.setType !== 1;
    },
    selectedContacts: {
      cache: false,
      get() {
        if (this.filter !== '') {
          return this.contacts.filter((contact) => {
            const name = contact.name.toLowerCase();
            return name.includes(this.filter.toLowerCase());
          });
        }
        return this.contacts;
      },
    },
    contactsSchema() {
      return this.schema.filter((field) => !this.deletedSchemaFieldIds.includes(field.id));
    },
  },
  apollo: {
    personalizationVariables: {
      query: GetPersonalizedVariableValues,
      variables() {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: true,
          },
        });
        return {
          setId: this.setId,
        };
      },
      update(data) {
        this.contacts = data.getPersonalizedVariableValues;
        this.sortContacts();
        if (this.contacts.length === 0) {
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }
      },
      skip() {
        return !this.setId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    contactRowRendered() {
      this.numberOfContactRows++;
      if (this.numberOfContactRows === this.contacts.length) {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }
    },
    sortContacts() {
      // sort the personalization sets by name
      this.contacts.sort((a, b) => {
        const orderA = a.name;
        const orderB = b.name;
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    },
    closeRenameValueModal() {
      this.showUpdatePersonalizationValueModal = false;
      this.currentContactId = null;
      this.currentContactName = null;
    },
    openRenameModal(contactId, contactName) {
      this.currentContactId = contactId;
      this.currentContactName = contactName;
      this.showUpdatePersonalizationValueModal = true;
    },
    async renameContact(contact) {
      const updateContactIndex = this.contacts.findIndex(
        (contactObj) => contactObj.client_key === contact.oldId,
      );
      try {
        if (updateContactIndex === -1) {
          throw new Error('Contact not found');
        }
        let { variable_data } = this.contacts[updateContactIndex];
        if (!variable_data) {
          variable_data = null;
        }
        this.showUpdatePersonalizationValueModal = false;
        // we update the contact in the database
        const obj = {
          client_key: contact.id,
          enabled: true,
          name: contact.name,
          personalized_variable_set_id: this.setId,
          value_order: this.contacts[updateContactIndex].value_order,
          variable_data: JSON.stringify(variable_data),
        };
        await this.$apollo.mutate({
          mutation: UpdatePersonalizedVariableValues,
          variables: {
            obj,
          },
        });
        this.$emit('update-personalization-value');
        this.contacts[updateContactIndex].name = contact.name;
        await this.$apollo.queries.personalizationVariables.refresh();
      } catch (e) {
        console.error(e);
      }
    },
    async createNewPersonalizationEntry(valueObject) {
      // update the database
      const highestOrder = (this.contacts.length)
        ? this.contacts[this.contacts.length - 1].value_order
        : 0;
      try {
        const obj = {
          personalized_variable_set_id: this.setId,
          name: valueObject.name,
          client_key: valueObject.id,
          enabled: true,
          value_order: highestOrder + 1,
        };
        await this.$apollo.mutate({
          mutation: InsertPersonalizedVariableValue,
          variables: {
            obj,
          },
        });
        // update contacts
        this.contacts.push(obj);
      } catch (e) {
        console.error(e);
      }
      await this.$apollo.queries.personalizationVariables.refresh();
      this.showNewPersonalizationEntryModal = false;
    },
    async updateFieldValue(value, valueKey, clientKey) {
      const contact = this.contacts.find((contactObj) => contactObj.client_key === clientKey);
      if (contact.variable_data) {
        contact.variable_data[valueKey] = value;
      } else {
        contact.variable_data = {};
        contact.variable_data[valueKey] = value;
      }
      // update the value in the database
      const obj = {
        personalized_variable_set_id: this.setId,
        name: contact.name,
        enabled: true,
        client_key: clientKey,
        value_order: contact.value_order,
        variable_data: JSON.stringify(contact.variable_data),
      };
      await this.$apollo.mutate({
        mutation: UpdatePersonalizedVariableValues,
        variables: {
          obj,
        },
      });
      this.valueIsSaved = true;
      this.$emit('update-personalization-value');
    },
    openDeleteContactModal(contactId, contactName) {
      this.currentContactId = contactId;
      this.currentContactName = contactName;
      this.showDeleteContactModal = true;
    },
    closeDeleteContactModal() {
      this.showDeleteContactModal = false;
      this.currentContactId = null;
      this.currentContactName = null;
    },
    reimportContacts(reimportData) {
      this.$emit('reimport-contacts', reimportData);
      this.showReimportContactsModal = false;
    },
    closeReimportContactsModal() {
      this.showReimportContactsModal = false;
    },
    async deleteContact(contactId) {
      try {
        await this.$apollo.mutate({
          mutation: DeletePersonalizedVariableValues,
          variables: {
            id: contactId,
            setId: this.setId,
          },
        });
        // update contacts
        this.contacts = this.contacts.filter((contact) => contact.client_key !== contactId);
        this.$emit('update-personalization-value');
      } catch (e) {
        console.error(e);
      }
      this.showDeleteContactModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.schema-wrapper {
  border: 1px solid $webmag-light-grey;
  .schema-item {
    background-color: #53575B;;
  }
}
.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
/deep/ .dropdown-item.disabled {
  cursor: not-allowed;
  pointer-events: inherit;
}
.empty-contact {
  background-color: #53575B;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
}
</style>
