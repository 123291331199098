var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-5" },
    [
      _c(
        "b-col",
        {
          staticClass: "d-flex align-items-center",
          attrs: { cols: "12", "offset-sm": "1", sm: "3" }
        },
        [
          _c("h3", { staticClass: "mb-0 text-uppercase" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("settings.personalization.contacts")) + " "
            )
          ]),
          _vm.hasWriteAccess
            ? _c(
                "b-dropdown",
                {
                  staticClass: "context-menu",
                  attrs: {
                    "toggle-class": "dot-button",
                    "no-caret": "",
                    "no-flip": "",
                    right: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("b-icon", {
                              attrs: {
                                icon: "three-dots-vertical",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2014744074
                  )
                },
                [
                  _vm.isImportedContacts
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.showReimportContactsModal = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("dropdowns.reimport")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("dropdowns.import")) + " ")
                  ]),
                  _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("dropdowns.export")) + " ")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "align-content-end", attrs: { cols: "12", sm: "8" } },
        [
          _c(
            "div",
            { staticClass: "float-right d-flex" },
            [
              _c(
                "b-input-group",
                {
                  class: `search-group d-flex align-items-center ${
                    _vm.hasWriteAccess ? "mr-3" : "mr-0"
                  }`
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "search", "aria-hidden": "true", scale: "1" }
                  }),
                  _c("b-form-input", {
                    attrs: {
                      id: "filter-input",
                      type: "search",
                      placeholder: _vm.$t("webmag.search")
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _vm.hasWriteAccess
                ? _c(
                    "b-button",
                    {
                      staticClass: "green-button-transparent border-0",
                      on: {
                        click: function($event) {
                          _vm.showNewPersonalizationEntryModal = true
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2 top--3",
                        attrs: {
                          icon: "plus-circle-fill",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("buttons.addPersonalizationEntry")) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showNewPersonalizationEntryModal
            ? _c("modal-create-new-personalization-entry", {
                attrs: { "set-id": _vm.setId },
                on: {
                  "create-personalization-entry":
                    _vm.createNewPersonalizationEntry,
                  "close-create-entry": function($event) {
                    _vm.showNewPersonalizationEntryModal = false
                  }
                }
              })
            : _vm._e(),
          _vm.showUpdatePersonalizationValueModal
            ? _c("modal-rename-contact", {
                attrs: {
                  "id-prop": _vm.currentContactId,
                  "name-prop": _vm.currentContactName
                },
                on: {
                  "rename-value": _vm.renameContact,
                  "close-create-value-modal": _vm.closeRenameValueModal
                }
              })
            : _vm._e(),
          _vm.showDeleteContactModal
            ? _c("modal-delete-contact", {
                attrs: { "id-prop": _vm.currentContactId },
                on: {
                  "delete-contact": _vm.deleteContact,
                  "close-delete-entry-modal": _vm.closeDeleteContactModal
                }
              })
            : _vm._e(),
          _vm.showReimportContactsModal
            ? _c("modal-reimport-contacts", {
                attrs: { "personalization-set": _vm.personalizationSet },
                on: {
                  "reimport-microsoft-data": _vm.reimportContacts,
                  "close-modal": _vm.closeReimportContactsModal
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.selectedContacts.length > 0
        ? _c(
            "b-col",
            {
              staticClass: "mt-4",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            _vm._l(_vm.selectedContacts, function(contact, index) {
              return _c("contacts-row-slide", {
                key: index,
                attrs: {
                  order: index + 1,
                  id: contact.client_key,
                  name: contact.name,
                  schema: _vm.contactsSchema,
                  "value-is-saved": _vm.valueIsSaved,
                  values: contact.variable_data,
                  "has-write-access": _vm.hasWriteAccess
                },
                on: {
                  "open-rename-modal": _vm.openRenameModal,
                  "update-field-value": _vm.updateFieldValue,
                  "delete-contact": _vm.openDeleteContactModal,
                  "value-save-status-completed": function($event) {
                    _vm.valueIsSaved = false
                  },
                  "row-rendered": _vm.contactRowRendered
                }
              })
            }),
            1
          )
        : _c(
            "b-col",
            {
              staticClass: "mt-4",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("div", { staticClass: "empty-contact py-2 text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.personalization.emptyContacts")) +
                    " "
                )
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }